import React from 'react'
import {Link} from 'gatsby'
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
    {
        allStrapiBlog(sort: {fields: date, order: DESC}) {
            nodes {
                title
                slug
                image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                date (formatString:"DD MMMM, YYYY", locale:"es-mx")
            }
        }
    }
`

const BlogSidebar = () => {
    const data = useStaticQuery(query)
    const {
        allStrapiBlog: { nodes: blogs },
    } = data

    return (
        <div className="widget-area">
            <div className="widget widget_search">
                <h3 className="widget-title">Búsqueda</h3>

                <form className="search-form">
                    <label>
                        <span className="screen-reader-text">Buscar:</span>
                        <input type="search" className="search-field" placeholder="Buscar..." />
                    </label>
                    <button type="submit">
                        <i className="bx bx-search-alt"></i>
                    </button>
                </form>
            </div>

            <div className="widget widget_tracer_posts_thumb">
                <h3 className="widget-title">Posts populares</h3>

                {
                    blogs.map((blog, idx) => {
                        return(
                            <article className="item" key={idx}>
                                <Link to={`/blogs/${blog.slug}`} className="thumb">
                                    <Image className="fullimage cover" role="img" 
                                        fluid={blog.image.childImageSharp.fluid} 
                                    />
                                </Link>
                                <div className="info">
                                    <span>{blog.date}</span>
                                    <h4 className="title usmall">
                                        <Link to={`/blogs/${blog.slug}`}>
                                            {blog.title}
                                        </Link>
                                    </h4>
                                </div>

                                <div className="clear"></div>
                            </article>
                            )
                        })
                    }

            </div>
        </div>
    )
}

export default BlogSidebar